import api, { source } from '@/api/config';
import { AxiosRequestConfig } from 'axios';
import { Loading, loadingClose } from '@/components/index';
import i18n from '../i18n';

interface RequestConfig extends AxiosRequestConfig {
  retry?: number;
}

const { t } = i18n.global;
export const $get = (
  url: string,
  params: object = {},
  toast: boolean = true,
  cancel: boolean = false,
  retry = 0,
): any => {
  if (toast == true) {
    Loading({ icon: 'loading', text: t('common.loading') });
  }
  let requestData = { url, method: 'get', params, retry };
  if (cancel == true) {
    requestData = Object.assign(requestData, { cancelToken: source.token });
  }
  return new Promise((resolve, reject) => {
    api(requestData)
      .then((res: any) => {
        if (toast == true) {
          loadingClose();
        }
        resolve(res.data);
      })
      .catch((err) => {
        if (toast == true) {
          loadingClose();
        }
        reject(err);
      });
  });
};
export const $post = (
  url: string,
  params: object = {},
  toast: boolean = true,
  config?: RequestConfig,
): any => {
  if (toast == true) {
    Loading({ icon: 'loading', text: t('common.loading') });
  }
  return new Promise((resolve, reject) => {
    api({
      url,
      method: 'post',
      data: params,
      ...config,
    })
      .then((res: any) => {
        if (toast == true) {
          loadingClose();
        }
        if (res.data) {
          resolve(res.data);
        }
      })
      .catch((err) => {
        if (toast == true) {
          loadingClose();
        }
        reject(err);
      });
  });
};
