import { defineStore } from 'pinia';
import {
  searchDataValueType,
  CountryItemType,
  CurrencyListType,
  CountryListType,
  OrderDetailDataType,
} from '@/assets/type/page';
type apiType = {
  verson: string; // 版本号（用于防止缓存结构变更等情况导致页面读取缓存异常问题）
  FirstEntry: boolean; // 是否首次进入
  sourceCode: string | null; // 来源key值
  utmSource: string | null; // 广告来源值
  isLocalStorage: boolean; // 是否能使用缓存
  Language: { [T: string]: string }; // 当前语种
  currency: { [T: string]: string }; // 使用币种信息
  languageList: { [T: string]: string }[]; // 语种列表
  residence: CountryItemType; // 用户所在地区信息
  searchInfo: searchDataValueType | null; // 搜索数据信息
  orderInfo: OrderDetailDataType | null; // 商品详情信息
  countryList: CountryListType | null; // 国家地区列表
  currencyList: CurrencyListType | null; // 国家币种列表
  keepalive: boolean; // 页面缓存状态
  queryData: { [T: string]: string }; // 全局路由参数（全局需携带参数）
  // PickupPrices: Array<unknown>; // 当地费用及税
};
export const useDefaultStore = defineStore<'default', apiType>('default', {
  // 推荐使用 完整类型推断的箭头函数
  state: () => {
    return {
      // 所有这些属性都将自动推断其类型
      FirstEntry: true,
      verson: '1.3',
      sourceCode: null,
      utmSource: null,
      isLocalStorage: true,
      Language: { name: 'English', alias: 'EN', value: 'en' },
      currency: { Code: 'CNY', Name: 'Chinese Yuan Reminbi', Prefix: 'C' },
      searchInfo: null,
      orderInfo: null,
      residence: {
        DiallingCode: '852',
        Code: 'HK',
        Name: 'Hong Kong(China)',
        Prefix: null,
      },
      languageList: [
        {
          name: 'English',
          alias: 'EN',
          value: 'en',
        },
        {
          name: '简体中文',
          alias: 'ZH',
          value: 'zh-CN',
        },
        {
          name: '繁体中文',
          alias: 'TW',
          value: 'zh-TW',
        },
        {
          name: '日本語',
          alias: 'JA',
          value: 'ja',
        },
      ],
      countryList: null,
      keepalive: true,
      currencyList: null,
      queryData: {},
      // PickupPrices: [],
    };
  },
});
