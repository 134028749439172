import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import i18n from '@/i18n';
import { computed } from 'vue';
const toObject = require('dayjs/plugin/toObject');
dayjs.extend(toObject);
export const getNow = (format: string) => {
  return dayjs().format(format);
};
// 格式化日期时间
export const getDate = (time: any, format: string) => {
  return dayjs(time).format(format);
};
// 获取时间对象
export const getDateJson = (time: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let data: any = dayjs(time).toObject();
  data.months = data.months + 1;
  if (data.hours < 10) {
    data.hours = '0' + data.hours;
  }
  if (data.minutes < 10) {
    data.minutes = '0' + data.minutes;
  }
  data = Object.assign(data, {
    week: parseInt(dayjs(time).format('d')),
    timeSlot: dayjs(time).format('A'),
  });
  return data;
};
// 时间对象转日期字符串格式
export const JsonParseDate = (time: any) => {
  let date = '';
  date = time.years + '-';
  if (time.months < 10) {
    date = date + '0';
  }
  date = date + time.months + '-';
  if (time.date < 10) {
    date = date + '0';
  }
  date = date + time.date + ' ';
  if (time.hours < 10) {
    date = date + '0';
  }
  date = date + time.hours + ':' + time.minutes;
  return date;
};
// 是否在有效时间内
export const isExpires = (time: string | number, expiresTime: string) => {
  dayjs.extend(isSameOrBefore);
  return dayjs(time).isAfter(expiresTime);
};
// 获取日期之间差异(天数展示）
export const getDateDiff = (startDate: string, endDate: string, type: any) => {
  const date1 = dayjs(startDate);
  const date2 = dayjs(endDate);
  return Math.ceil(date2.diff(date1, type, true)) + 1;
};
// 获取中国北京时间
export const getChinaTime = () => {
  const timezone = 8;
  const offset_GMT = new Date().getTimezoneOffset();
  const nowDate = new Date().getTime();
  const targetDate = new Date(
    nowDate + offset_GMT * 60 * 1000 + timezone * 60 * 60 * 1000,
  );
  const month = targetDate.getMonth() + 1;
  const nowTime =
    targetDate.getFullYear() +
    '-' +
    month +
    '-' +
    targetDate.getDate() +
    ' ' +
    targetDate.getHours() +
    ':' +
    targetDate.getMinutes() +
    ':' +
    targetDate.getSeconds();
  return nowTime;
};
// 星期数列表
export const weekDayList = computed(() => {
  const { t }: any = i18n.global;
  return [
    {
      name: t('common.date.week.sunday'),
    },
    {
      name: t('common.date.week.monday'),
    },
    {
      name: t('common.date.week.tuesday'),
    },
    {
      name: t('common.date.week.wednesday'),
    },
    {
      name: t('common.date.week.thursday'),
    },
    {
      name: t('common.date.week.friday'),
    },
    {
      name: t('common.date.week.saturday'),
    },
  ];
});
// 月份显示列表
export const monthList = computed(() => {
  const { t }: any = i18n.global;
  return [
    {
      name: '',
    },
    {
      name: t('common.date.month.january'),
    },
    {
      name: t('common.date.month.february'),
    },
    {
      name: t('common.date.month.march'),
    },
    {
      name: t('common.date.month.april'),
    },
    {
      name: t('common.date.month.may'),
    },
    {
      name: t('common.date.month.june'),
    },
    {
      name: t('common.date.month.july'),
    },
    {
      name: t('common.date.month.august'),
    },
    {
      name: t('common.date.month.september'),
    },
    {
      name: t('common.date.month.october'),
    },
    {
      name: t('common.date.month.november'),
    },
    {
      name: t('common.date.month.december'),
    },
  ];
});

// 获取当前中国时间
export const chineseNowTime = () => {
  // 获取中国时间戳
  // 目标时区，东8区
  const targetTimezone = -8;
  // 当前时区与中时区时差，以min为维度
  const _dif = new Date().getTimezoneOffset();
  // 本地时区时间 + 时差  = 中时区时间
  // 目标时区时间 + 时差 = 中时区时间
  // 目标时区时间 = 本地时区时间 + 本地时区时差 - 目标时区时差
  // 东9区时间
  const east8time =
    new Date().getTime() + _dif * 60 * 1000 - targetTimezone * 60 * 60 * 1000;
  return dayjs(east8time).format('YYYY-MM-DD HH:mm:ss');
};
