<template>
  <div class="">
    <van-popup
      v-model:show="newValue"
      round
      position="bottom"
      :close-on-click-overlay="false"
    >
      <div class="dialog-box">
        <div class="dialog-title-box">
          <p class="title">{{ $t('components.cookieDialog.Title') }}</p>
          <i class="iconfont icon-ico-danchuceng-guanbi" @click="close"></i>
        </div>
        <div class="dialog-content-box">
          <p class="content-text">
            {{ $t('components.cookieDialog.Content') }}
          </p>
          <div class="view-box" v-if="showPreferences == true">
            <van-collapse v-model="activeNames" accordion>
              <van-collapse-item
                :title="$t('components.cookieDialog.QAQs_Title_01')"
                name="Functional"
                :is-link="false"
              >
                <template #value>
                  <p class="success-text">
                    {{ $t('components.cookieDialog.QAQs_Value_01') }}
                  </p>
                </template>
                <template #right-icon>
                  <i
                    class="iconfont icon-ico-yuanjiantou-xianxing-h14-xiangxia1"
                  ></i>
                  <i
                    class="iconfont icon-a-ico-yuanjiantou-xianxing-h14-xiangxia1"
                  ></i>
                </template>
                <p class="content">
                  {{ $t('components.cookieDialog.QAQs_Content_01') }}
                </p>
              </van-collapse-item>
              <van-collapse-item
                :title="$t('components.cookieDialog.QAQs_Title_02')"
                name="Statistics"
                :is-link="false"
              >
                <template #value>
                  <van-switch
                    v-model="CookieList.Statistics"
                    @click.stop="test"
                    size="14px"
                    active-color="rgba(255, 213, 0, 1)"
                    inactive-color="#dcdee0"
                  />
                </template>
                <template #right-icon>
                  <i
                    class="iconfont icon-ico-yuanjiantou-xianxing-h14-xiangxia1"
                  ></i>
                  <i
                    class="iconfont icon-a-ico-yuanjiantou-xianxing-h14-xiangxia1"
                  ></i>
                </template>
                <p class="content">
                  {{ $t('components.cookieDialog.QAQs_Content_02') }}
                </p>
              </van-collapse-item>
              <van-collapse-item
                :title="$t('components.cookieDialog.QAQs_Title_03')"
                name="Marketing"
                :is-link="false"
              >
                <template #value>
                  <van-switch
                    v-model="CookieList.Marketing"
                    @click.stop="test"
                    size="14px"
                    active-color="rgba(255, 213, 0, 1)"
                    inactive-color="#dcdee0"
                  />
                </template>
                <template #right-icon>
                  <i
                    class="iconfont icon-ico-yuanjiantou-xianxing-h14-xiangxia1"
                  ></i>
                  <i
                    class="iconfont icon-a-ico-yuanjiantou-xianxing-h14-xiangxia1"
                  ></i>
                </template>
                <p class="content">
                  {{ $t('components.cookieDialog.QAQs_Content_03') }}
                </p>
              </van-collapse-item>
            </van-collapse>
          </div>
          <div class="button-box">
            <van-button
              class="accept-button"
              type="primary"
              block
              round
              @click="confirm('Accept')"
              >{{ $t('components.cookieDialog.button_01') }}</van-button
            >
            <van-button
              class="gray-button deny-button"
              type="primary"
              block
              round
              @click="confirm('Deny')"
              >{{ $t('components.cookieDialog.button_02') }}</van-button
            >
            <van-button
              class="gray-button view-button"
              type="primary"
              block
              round
              v-show="showPreferences == false"
              @click="viewPreferences"
              >{{ $t('components.cookieDialog.button_03') }}</van-button
            >
            <van-button
              class="gray-button save-button"
              type="primary"
              block
              round
              v-show="showPreferences == true"
              @click="confirm('custom')"
              >{{ $t('components.cookieDialog.button_04') }}</van-button
            >
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive, computed } from 'vue';
const emit = defineEmits(['update:modelValue', 'confirm']);
type propsType = {
  modelValue: boolean;
};
const props = withDefaults(defineProps<propsType>(), {});
const newValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
const activeNames = ref<any>();
const showPreferences = ref<boolean>(false);
const CookieList = reactive<{ [T: string]: boolean }>({
  Functional: true,
  Statistics: true,
  Marketing: true,
});
const test = () => {};
const viewPreferences = () => {
  showPreferences.value = true;
};

const confirm = (name: string) => {
  if (name == 'Accept') {
    localStorage.setItem(
      'CookieList',
      JSON.stringify({ Functional: true, Statistics: true, Marketing: true }),
    );
  } else if (name == 'Deny') {
    localStorage.setItem(
      'CookieList',
      JSON.stringify({ Functional: true, Statistics: false, Marketing: false }),
    );
  } else if (name == 'custom') {
    localStorage.setItem('CookieList', JSON.stringify(CookieList));
  }
  localStorage.setItem('CookieStatus', name);
  emit('confirm', name);
  emit('update:modelValue', false);
};
const close = () => {
  confirm('Deny');
};
</script>

<style lang="scss" scoped>
:deep(.van-popup--bottom) {
  width: calc(100% - 5.333333vw);
  left: 2.666667vw;
}
:deep(.van-collapse-item) {
  .van-cell {
    padding-left: 0;
    padding-right: 0;
  }
  .van-cell__title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: var(--black-color);
  }
  .van-cell__value {
    display: flex;
    align-items: center;
    justify-content: right;
    padding-right: 10px;
  }
  .van-collapse-item__content {
    padding-left: 0;
    padding-right: 0;
  }
  .content {
    font-size: 10px;
    font-weight: 400;
    line-height: 13.72px;
    color: var(--theme-grey-color);
  }
  .iconfont {
    color: rgba(0, 122, 255, 1);
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .icon-a-ico-yuanjiantou-xianxing-h14-xiangxia1 {
    display: none;
  }

  .van-collapse-item__title--expanded {
    .icon-ico-yuanjiantou-xianxing-h14-xiangxia1 {
      // transform: rotate(-180deg);
      display: none;
    }
    .icon-a-ico-yuanjiantou-xianxing-h14-xiangxia1 {
      display: flex;
    }
  }
}
.dialog-box {
  .dialog-title-box {
    height: 50px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: var(--yellow-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: var(--black-color);
    }
    .iconfont {
      font-size: 24px;
    }
  }
  .dialog-content-box {
    padding: 10px 20px;
    box-sizing: border-box;
    .content-text {
      font-size: 10px;
      font-weight: 400;
      line-height: 13.72px;
      color: var(--black-color);
    }
    .view-box {
      margin-top: 10px;
      .success-text {
        color: rgba(0, 170, 0, 1);
        font-size: 12px;
        font-weight: 400;
        line-height: 16.46px;
      }
    }
    .button-box {
      .van-button--primary {
        margin-top: 10px;
      }
      .gray-button {
        background-color: var(--body-theme-color);
        border-color: var(--body-theme-color);
      }
    }
  }
}
</style>
