import i18n from '@/i18n';
import { VueI18nTranslation } from 'vue-i18n';
const t: VueI18nTranslation = i18n.global.t;
// 校验邮箱
export const checkEmail = (value: string) => {
  let errorMessage: string = '';
  if (value.length == 0) {
    errorMessage = t('common.errormessage.message_01');
  } else if (value.length > 0) {
    const reg =
      /^[A-Za-z0-9]+([._\\-]*[A-Za-z0-9])*@([A-Za-z0-9]+[-A-Za-z0-9]*[A-Za-z0-9]+.){1,63}[A-Za-z0-9]+$/;
    if (!reg.test(value)) {
      errorMessage = t('common.errormessage.message_02');
    }
  }
  return errorMessage;
};
// 校验名字
export const checkName = (label: string, value: string) => {
  let errorMessage: string = '';
  if (value.length == 0) {
    errorMessage = t('common.errormessage.commonmessage', { name: label });
  } else if (value.length > 0) {
    const reg = /^[a-zA-Z]+$/;
    if (!reg.test(value)) {
      errorMessage = t('common.errormessage.commonmessage_01', { name: label });
    }
  }
  return errorMessage;
};
// 校验电话号码
export const CheckContactNumber = (value: string) => {
  let errorMessage: string = '';
  if (value.length == 0) {
    errorMessage = t('common.errormessage.message_03');
  } else if (value.length > 0) {
    const reg = /^(\+|\d{1,3})?\d{4,14}$/;
    if (!reg.test(value)) {
      errorMessage = t('common.errormessage.message_04');
    }
  }
  return errorMessage;
};
// 校验邮政编码
export const CheckPostCode = (value: string) => {
  let errorMessage: string = '';
  if (value.length == 0) {
    errorMessage = t('common.errormessage.message_05');
  } else {
    const reg = /[\dA-Za-z]+/;
    if (!reg.test(value)) {
      errorMessage = t('common.errormessage.message_06');
    }
  }
  return errorMessage;
};
// 校验是否为空
export const CheckIsEmpty = (label: string, value: string) => {
  let errorMessage: string = '';
  if (value.length == 0) {
    errorMessage = t('common.errormessage.commonmessage', { name: label });
  }
  return errorMessage;
};
// 是否能使用localstorage（无痕模式）
export const isLocalStorage = () => {
  const testKey = 'test';
  try {
    localStorage.setItem(testKey, 'testValue');
    localStorage.removeItem(testKey);
    return true;
  } catch (error) {
    return false;
  }
};
