export const getCookie = (name: string) => {
  // eslint-disable-next-line no-var
  var arr;
  const reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');

  if ((arr = document.cookie.match(reg))) {
    return arr[2];
  } else {
    return null;
  }
};

export const setCookie = (
  c_name: string,
  value: string,
  expiredays: number,
  path = '/',
) => {
  const exdate = new Date();
  exdate.setDate(exdate.getDate() + expiredays);
  document.cookie =
    c_name +
    '=' +
    escape(value) +
    (expiredays == null ? '' : ';expires=' + exdate.toUTCString()) +
    `;path=${path}`;
};

export const delCookie = (name: string) => {
  const exp = new Date();
  exp.setTime(exp.getTime() - 1);
  const cval = getCookie(name);
  if (cval != null)
    document.cookie = name + '=' + cval + ';expires=' + exp.toUTCString();
};
