import Vant from 'vant';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n from './i18n';
import 'vant/lib/index.css';
import '@/assets/css/index.scss';
import { createPinia } from 'pinia';
const app = createApp(App);
app.use(i18n);
app.use(createPinia());
app.use(router);
app.use(Vant);
router.isReady().then(() => app.mount('#app'));
