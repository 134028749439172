export const px2vw = (px: number): string => `${(100 / 375) * px}vw`;

// 节流
export function throttle(
  // eslint-disable-next-line no-unused-vars
  fn: { apply: (arg0: any, arg1: any[]) => void },
  t: number,
) {
  let flag = true;
  const interval = t || 500;
  return function (this: any, ...args: any) {
    if (flag) {
      fn.apply(this, args);
      flag = false;
      setTimeout(() => {
        flag = true;
      }, interval);
    }
  };
}

// 节点防抖
export function debounce(
  // eslint-disable-next-line no-unused-vars
  fn: { apply: (arg0: any, arg1: any) => void },
  t: number,
) {
  let timeId: any = null;
  const delay = t || 500;
  return function (this: any, ...args: any) {
    if (timeId) {
      clearTimeout(timeId);
    }
    timeId = setTimeout(() => {
      timeId = null;
      fn.apply(this, args);
    }, delay);
  };
}
// 节点防抖
type voidFnType = () => void;
type debounceTarget = EventTarget & { debounce?: voidFnType };
export const debounceDom = (
  target: debounceTarget,
  callback: voidFnType,
  delay = 200,
) => {
  if (!target.debounce) {
    target.debounce = (() => {
      let timer: any;
      return () => {
        clearTimeout(timer);
        timer = window.setTimeout(() => {
          callback.apply(callback);
        }, delay);
      };
    })();
  }

  target.debounce();
};

// 网络图片转换为本地图片路径
export const fetchImage = async (img: string) => {
  const response = await fetch(img);
  const blob = await response.blob();
  return URL.createObjectURL(blob);
};

// 路由返回上一页（跳转判断）
export const routerBack = () => {
  if (window.history.length > 1) {
    window.history.back();
  } else {
    window.location.href = '/carrental';
  }
};

/**
 * @method hasProperty 判断对象中是否存在某个属性
 * @param {Object} obj
 * @param {String} key
 * @returns Boolean
 */
export function hasProperty(obj: any, key: string) {
  let result = null;
  for (const i in obj) {
    if (i.toLocaleUpperCase() == key.toLocaleUpperCase()) {
      result = obj[i];
      break;
    }
  }
  return result;
}
