import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { addSiteLog, pvLogSaveLog } from '@/api/index';
import dayjs from 'dayjs';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'cur_index',
    component: () => import('@/views/cur/index.vue'),
  },
  // 租车首页
  {
    path: '/carrental',
    name: 'index',
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/menu/index.vue'),
  },
  {
    path: '/select_currency',
    name: 'select_currency',
    component: () => import('@/views/menu/select_currency.vue'),
  },
  {
    path: '/select_language',
    name: 'select_language',
    component: () => import('@/views/menu/select_language.vue'),
  },
  {
    path: '/search_result',
    name: 'search_result',
    meta: { keepAlive: true },
    component: () => import('@/views/search/index.vue'),
  },
  {
    path: '/search_filter',
    name: 'search_filter',
    component: () => import('@/views/search/filter.vue'),
  },
  // 租车产品详情页面
  {
    path: '/detail/index',
    name: 'your_deals',
    meta: { keepAlive: false },
    component: () => import('@/views/detail/your_deal.vue'),
  },
  // 租车产品详情页面
  {
    path: '/detail/:pathMatch(.*)*',
    name: 'your_detail',
    meta: { keepAlive: false },
    component: () => import('@/views/detail/your_deal.vue'),
  },
  {
    path: '/protection_options',
    name: 'protextion_options',
    component: () => import('@/views/book/protection_options.vue'),
  },
  {
    path: '/checkout',
    name: 'check_out',
    component: () => import('@/views/book/checkout.vue'),
  },
  {
    path: '/activity/index',
    name: 'activity_index',
    component: () => import('@/views/activity/index.vue'),
  },
  {
    path: '/order',
    name: 'order',
    children: [
      {
        path: 'index',
        name: 'order_index',
        component: () => import('@/views/order/index.vue'),
      },
      {
        name: 'pay_index',
        path: 'pay/index',
        component: () => import('@/views/order/pay/pay.vue'),
      },
      {
        path: 'pay/result',
        name: 'pay_result',
        component: () => import('@/views/order/pay/result.vue'),
      },
      {
        path: 'detail/index',
        name: 'order_detail_index',
        component: () => import('@/views/order/detail/index.vue'),
      },
      {
        path: 'detail/cancel',
        name: 'order_detail_cancel',
        component: () => import('@/views/order/detail/cancel.vue'),
      },
      {
        path: 'proof_of_purchase',
        name: 'proof_of_purchase',
        component: () => import('@/views/order/proof_of_purchase.vue'),
      },
      {
        path: 'rental_voucher',
        name: 'rental_voucher',
        component: () => import('@/views/order/rental_voucher.vue'),
      },
    ],
  },
  {
    path: '/help',
    name: 'help',
    children: [
      {
        path: 'index',
        name: 'help_index',
        component: () => import('@/views/help/index.vue'),
      },
      {
        path: 'privacy_statement',
        name: 'help_privacy_statement',
        component: () => import('@/views/help/privacy_statement.vue'),
      },
      {
        path: 'cookie',
        name: 'help_cookie',
        component: () => import('@/views/help/cookie.vue'),
      },
      {
        path: 'terms_conditions',
        name: 'help_terms_conditions',
        component: () => import('@/views/help/terms_and_conditions.vue'),
      },
      {
        path: 'about_us',
        name: 'aboutUs',
        component: () => import('@/views/help/about_us.vue'),
      },
      {
        path: 'contact_us',
        name: 'help_contact_us',
        component: () => import('@/views/help/contact_us.vue'),
      },
    ],
  },
  // 订单管理页面
  {
    path: '/manage',
    name: 'manage',
    children: [
      {
        path: 'mybook',
        name: 'manage_my_book',
        component: () => import('@/views/manage/my_booking.vue'),
      },
    ],
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/policy.vue'),
  },
];

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

const exceptionRouterList = [
  // 特殊路由跳转列表
  {
    path: '/carrental',
  },
  {
    path: '/select_currency',
  },
  {
    path: '/select_language',
  },
  {
    path: '/checkout',
    jump: '/detail/index',
    jumps: '/detail/api',
  },
];
router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV === 'production') {
    const flag = navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    );
    if (flag == null) {
      const xwhost = process.env.VUE_APP_JUMP_URL;
      let hrefstr = xwhost;
      let issetPath = false;
      for (let i = 0; i < exceptionRouterList.length; i++) {
        console.log('exceptionRouterList[i].path', exceptionRouterList[i].path);
        if (exceptionRouterList[i].path == to.path) {
          console.log(
            'exceptionRouterList[i].path',
            exceptionRouterList[i].path,
          );
          if (
            exceptionRouterList[i].jump &&
            exceptionRouterList[i].jump != ''
          ) {
            const params = to.fullPath.slice(
              to.path.length,
              to.fullPath.length,
            );
            if (params[0] == '?') {
              hrefstr = hrefstr + exceptionRouterList[i].jump + params;
            } else if (params[0] == '/') {
              hrefstr = hrefstr + exceptionRouterList[i].jumps + params;
            }
          }
          issetPath = true;
          break;
        }
      }
      if (issetPath == false) {
        hrefstr = hrefstr + to.fullPath;
      }
      // 保存其他网站跳转url
      const urlGroup = [process.env.VUE_APP_CURRENT_URL, ''];
      if (!urlGroup.includes(document.referrer)) {
        sessionStorage.setItem('referrerUrl', document.referrer);
      }
      window.location.replace(hrefstr);
    } else {
      const pvlog = sessionStorage.getItem('pvlog');
      if (pvlog == null && process.env.NODE_ENV == 'production') {
        sessionStorage.setItem('pvlog', 'true');
        try {
          pvLogSaveLog({}, false)
            .then(() => {})
            .catch(() => {});
        } catch {}
      }
    }
  }
  next();
});

type ipInfoType = {
  Currency: string;
} | null;

let time: dayjs.Dayjs;
router.afterEach((to, from) => {
  const ipInfo: ipInfoType = JSON.parse(localStorage.getItem('IpInfo') || '{}');
  if (from.name !== '') {
    const params = {
      PageUrl: from.fullPath,
      IsLeave: true,
      TimeOnPage: dayjs().diff(time),
      Currency: ipInfo?.Currency,
    };
    addSiteLog(params);
  }
  const params = {
    PageUrl: to.fullPath,
    IsLeave: false,
    TimeOnPage: 0,
    Currency: ipInfo?.Currency,
  };
  addSiteLog(params);
  time = dayjs();
});

export default router;
