import { Locale } from 'vant';
import enUS from 'vant/es/locale/lang/en-US';
import zhCN from 'vant/es/locale/lang/zh-CN';
import zhTW from 'vant/es/locale/lang/zh-TW';
import jaJP from 'vant/es/locale/lang/ja-JP';

export const vantChangeLanguage = (language: string) => {
  if (language == 'en-US') {
    Locale.use('en-US', enUS);
  } else if (language == 'zh-CN') {
    Locale.use('zh-CN', zhCN);
  } else if (language == 'zh-TW') {
    Locale.use('zh-TW', zhTW);
  } else if (language == 'ja-Jp') {
    Locale.use('ja-Jp', jaJP);
  }
};
