import { createVNode, render } from 'vue';
import xwLoading from './index.vue';

const div = document.createElement('div');
div.setAttribute('class', 'Loading');
document.body.appendChild(div);
export type paramsType = {
  text: string;
  icon?: string;
  overlay?: boolean;
};
export const loading = (params: string | paramsType) => {
  let data: paramsType = { text: '' };
  if (typeof params === 'string') {
    data = { text: params, icon: 'loading' };
  } else {
    data = params;
  }
  const NODE = createVNode(xwLoading, data);
  render(NODE, div);
};
export const loadClose = () => {
  render(null, div);
};
