import axios from 'axios';
import { useDefaultStore } from '@/store';
import { getCookie } from '@/assets/utils/cookie';
import i18n from '@/i18n';
import { showNotify } from 'vant';

const { t } = i18n.global;

const setheader = () => {
  return { Currency: 'CNY', Language: 'EN', Source: 'H5' };
};
const setReferrer = () => {
  let previousURL = document.referrer;
  if (previousURL == '') {
    previousURL =
      window.location.host +
      (window.history.state.back != null ? window.history.state.back : '');
  }
  return previousURL;
};

const configResp = await axios.get('/config.json');

const api = axios.create({
  baseURL: configResp.data.baseUrl || process.env.VUE_APP_BASE_URL, // 配置 axios 请求服务器地址
  timeout: 30000, // 请求超时时间毫秒
  headers: setheader(),
});
api.interceptors.request.use(
  // 请求拦截
  async (config) => {
    const defaultStore = useDefaultStore();
    if (defaultStore.sourceCode != null) {
      config.headers.Code = defaultStore.sourceCode;
    }
    // if (defaultStore.utmSource != null) {
    //   config.headers.utm_source = defaultStore.utmSource;
    // }
    config.headers.Refererurl = setReferrer();
    config.headers.Currency = defaultStore.currency.Code;
    config.headers.Language = defaultStore.Language.value.toUpperCase();
    // config.headers.token = sessionStorage.getItem('token');
    config.headers.utm_SourceTravelerID =
      getCookie('utmSourceTravelerID') || undefined;
    config.headers.utm_source =
      defaultStore.utmSource || getCookie('utm_source') || undefined;
    return config;
  },
  (error) => {
    console.log(error, 'error');
  },
);

api.interceptors.response.use(
  // 响应拦截
  (response) => {
    if (response.status && response.status === 200) {
      return Promise.resolve(response);
    } else {
      showNotify(t('errormessage.message_12'));
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是200的情况
  (error) => {
    if (error.code == 'ERR_CANCELED') {
      return Promise.resolve({});
    }
    error.config.retryIndex = error.config.retryIndex || 0;
    if (!error.config.retry || error.config.retryIndex >= error.config.retry) {
      // 不重连或重连次数大于最多次数
      showNotify(t('errormessage.message_12'));
      return Promise.reject(error);
    }
    error.config.retryIndex++;
    const relink = new Promise((resolve) => {
      setTimeout(() => {
        resolve(null);
      }, 1000);
    });
    return relink.then(() => {
      return api(error.config);
    });
  },
);
export const source = axios.CancelToken.source();
export default api;
