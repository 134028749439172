<template>
  <!-- <router-view /> -->
  <router-view
    v-slot="{ Component }"
    v-if="defaultStore.countryList && defaultStore.currencyList"
  >
    <keep-alive>
      <component :is="Component" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <component :is="Component" v-if="!$route.meta.keepAlive" />
    <cookieDialog
      v-if="!CookieStatus && CookieStatus != 'Accept'"
      v-model="cookieShow"
    ></cookieDialog>
  </router-view>
</template>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
</style>
<script lang="ts" setup>
import { ref, onMounted, watch } from 'vue';
import { getIPDetail, getCurrencies, getCountries } from '@/api/index';
import cookieDialog from '@/views/help/components/cookieDialog.vue';
import { getChinaTime, isExpires } from '@/assets/utils/date';
import { isLocalStorage } from '@/assets/utils/verification';
import { useDefaultStore } from '@/store/index';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { vantChangeLanguage } from '@/assets/utils/vantLanguage';
import { hasProperty } from '@/assets/utils/utils';
const defaultStore = useDefaultStore();
const router = useRouter();
// const route = useRoute();
// const exceptionRouterList = [
//   // 特殊路由跳转列表
//   {
//     path: '/carrental',
//   },
//   {
//     path: '/select_currency',
//   },
//   {
//     path: '/select_language',
//   },
//   {
//     path: '/checkout',
//     jump: '/detail/index',
//     jumps: '/detail/api',
//   },
// ];
const I18n = useI18n();
const cookieShow = ref<boolean>(false);
const CookieStatus = localStorage.getItem('CookieStatus');
defaultStore.isLocalStorage = isLocalStorage();
onMounted(() => {
  // 支付风控代码引入
  const script = document.createElement('script');
  window.siteId = process.env.VUE_APP_SITEID;
  script.setAttribute('id', window.siteId);
  script.src = '/ftr.js';
  document.body.append(script);
  document.addEventListener('ftr:tokenReady', (evt: any) => {
    window.ftrToken = evt.detail;
  });
});
// 初始化基本数据
const initDefaultData = async () => {
  if (defaultStore.isLocalStorage == true) {
    const verson = localStorage.getItem('verson');
    if (verson == null || verson != defaultStore.verson) {
      localStorage.clear();
      localStorage.setItem('verson', defaultStore.verson);
    }
    const currencyList = localStorage.getItem('currencyList');
    const countryList = localStorage.getItem('countryList');
    const currencySelected = localStorage.getItem('currency');
    const residenceSelected = localStorage.getItem('residence');
    const currentLanguage = localStorage.getItem('language');
    const ipDetail = localStorage.getItem('IpInfo');
    if (CookieStatus != null) {
      if (CookieStatus == 'Accept') {
        cookieShow.value = false;
      } else if (CookieStatus == 'Deny' || CookieStatus == 'custom') {
        cookieShow.value = true;
      }
    } else {
      cookieShow.value = true;
    }
    if (countryList != null) {
      const countryListData = JSON.parse(countryList);
      if (
        isExpires(dayjs(getChinaTime()).unix(), countryListData.expires) == true
      ) {
        localStorage.removeItem('countryList');
        await initgetCountriesList();
      } else {
        defaultStore.countryList = countryListData;
      }
    } else {
      await initgetCountriesList();
    }
    if (currencyList != null) {
      const currencyListData = JSON.parse(currencyList);
      if (
        isExpires(dayjs(getChinaTime()).unix(), currencyListData.expires) ==
        true
      ) {
        localStorage.removeItem('currencyList');
        await initCurrencyList();
      } else {
        defaultStore.currencyList = currencyListData;
      }
    } else {
      await initCurrencyList();
    }
    if (currencySelected != null) {
      defaultStore.currency = JSON.parse(currencySelected);
    }
    if (residenceSelected != null) {
      defaultStore.residence = JSON.parse(residenceSelected);
    }
    if (currentLanguage != null) {
      defaultStore.Language = JSON.parse(currentLanguage);
      I18n.locale.value = defaultStore.Language.value;
    }
    if (ipDetail == null) {
      await initIpInfo();
    }
  }
};
// 检测PC端还是移动端设备
// const deviceChange = () => {
//   const flag = navigator.userAgent.match(
//     /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
//   );
//   if (flag == null) {
//     const xwhost = process.env.VUE_APP_JUMP_URL;
//     let hrefstr = xwhost;
//     let issetPath = false;
//     for (let i = 0; i < exceptionRouterList.length; i++) {
//       console.log('exceptionRouterList[i].path', exceptionRouterList[i].path);
//       if (exceptionRouterList[i].path == route.path) {
//         console.log('exceptionRouterList[i].path', exceptionRouterList[i].path);
//         if (exceptionRouterList[i].jump && exceptionRouterList[i].jump != '') {
//           const params = route.fullPath.slice(
//             route.path.length,
//             route.fullPath.length,
//           );
//           if (params[0] == '?') {
//             hrefstr = hrefstr + exceptionRouterList[i].jump + params;
//           } else if (params[0] == '/') {
//             hrefstr = hrefstr + exceptionRouterList[i].jumps + params;
//           }
//         }
//         issetPath = true;
//         break;
//       }
//     }
//     if (issetPath == false) {
//       hrefstr = hrefstr + route.fullPath;
//     }
//     window.location.replace(hrefstr);
//   } else {
//     const pvlog = sessionStorage.getItem('pvlog');
//     if (pvlog == null && process.env.NODE_ENV == 'production') {
//       sessionStorage.setItem('pvlog', 'true');
//       try {
//         pvLogSaveLog({}, false)
//           .then(() => {})
//           .catch(() => {});
//       } catch {}
//     }
//   }
// };
// 获取用户IP地址信息
const initIpInfo = async () => {
  const res = (await getIPDetail()) || { Data: {} };
  const language = defaultStore.languageList.filter((result) => {
    return (
      result.value.toLocaleUpperCase() == res.Data.Language.toLocaleUpperCase()
    );
  });
  if (
    language.length > 0 &&
    hasProperty(router.currentRoute.value.query, 'lang') == null &&
    hasProperty(router.currentRoute.value.query, 'language') == null
  ) {
    defaultStore.Language = language[0];
    I18n.locale.value = language[0].value;
    vantChangeLanguage(I18n.t('common.vant'));
    localStorage.setItem('language', JSON.stringify(language[0]));
    await initgetCountriesList();
  } else {
    I18n.locale.value = defaultStore.Language.value;
    localStorage.setItem('language', JSON.stringify(defaultStore.Language));
  }
  const currencyList = defaultStore.currencyList;
  const countryList = defaultStore.countryList;
  if (
    currencyList != null &&
    currencyList.Items != null &&
    hasProperty(router.currentRoute.value.query, 'currency') == null &&
    hasProperty(router.currentRoute.value.query, 'Currency') == null
  ) {
    const currency = currencyList.Items.filter((result) => {
      return result.Code == res.Data.Currency;
    })[0];
    if (typeof currency === 'undefined') {
      localStorage.setItem('currency', JSON.stringify(defaultStore.currency));
    } else {
      defaultStore.currency = currency;
      localStorage.setItem('currency', JSON.stringify(currency));
    }
  }
  if (countryList != null && countryList.Items != null) {
    const residence = countryList.Items.filter((result) => {
      return result.Code == res.Data.Country;
    })[0];
    if (typeof residence === 'undefined') {
      localStorage.setItem('residence', JSON.stringify(defaultStore.residence));
    } else {
      defaultStore.residence = residence;
      localStorage.setItem('residence', JSON.stringify(residence));
    }
  }

  localStorage.setItem('IpInfo', JSON.stringify(res.Data));
};
// 初始化币种列表
const initCurrencyList = async () => {
  const res = (await getCurrencies()) || { Data: {} };
  if (defaultStore.isLocalStorage == true) {
    const localStorageData = Object.assign(res.Data, {
      expires: dayjs(getChinaTime()).add(1, 'day').unix(),
    });
    localStorage.setItem('currencyList', JSON.stringify(localStorageData));
  }
  defaultStore.currencyList = res.Data;
};
// 初始化国家列表
const initgetCountriesList = async () => {
  const res = (await getCountries()) || { Data: {} };
  if (defaultStore.isLocalStorage == true) {
    const localStorageData = Object.assign(res.Data, {
      expires: dayjs(getChinaTime()).add(1, 'day').unix(),
    });
    localStorage.setItem('countryList', JSON.stringify(localStorageData));
  }
  defaultStore.countryList = res.Data;
  const languageResult = res.Data.Items.filter((results: any) => {
    return results.Code == defaultStore.residence.Code;
  });
  if (languageResult.length > 0) {
    defaultStore.residence = languageResult[0];
    localStorage.setItem('residence', JSON.stringify(languageResult[0]));
  }
};
// if (process.env.NODE_ENV === 'production') {
//   deviceChange();
// }
initDefaultData();
vantChangeLanguage(I18n.t('common.vant'));
watch(
  () => router.currentRoute.value.query,
  (query: any) => {
    if (defaultStore.FirstEntry == true) {
      if (query.code) {
        defaultStore.sourceCode = query.code;
      } else if (query.key || query.Key) {
        defaultStore.sourceCode = query.key || query.Key;
      }
      if (query.utm_source) {
        defaultStore.utmSource = query.utm_source;
        defaultStore.queryData = Object.assign(defaultStore.queryData, {
          utm_source: query.utm_source,
        });
      }
      if (query.utm_medium) {
        defaultStore.queryData = Object.assign(defaultStore.queryData, {
          utm_medium: query.utm_medium,
        });
      }
      if (query.utm_campaign) {
        defaultStore.queryData = Object.assign(defaultStore.queryData, {
          utm_campaign: query.utm_campaign,
        });
      }
      if (query.utm_term) {
        defaultStore.queryData = Object.assign(defaultStore.queryData, {
          utm_term: query.utm_term,
        });
      }
      if (
        hasProperty(query, 'lang') != null ||
        hasProperty(query, 'language')
      ) {
        const result = defaultStore.languageList.filter((res) => {
          const lang =
            hasProperty(query, 'language') || hasProperty(query, 'lang');
          return res.value.toLocaleUpperCase() == lang.toLocaleUpperCase();
        });
        if (result.length > 0) {
          defaultStore.Language = result[0];
          I18n.locale.value = result[0].value;
          localStorage.setItem('language', JSON.stringify(result[0]));
          vantChangeLanguage(I18n.t('common.vant'));
          initgetCountriesList();
          initCurrencyList();
        }
      }
      if (
        (query.Currency || query.currency) &&
        defaultStore.FirstEntry == true
      ) {
        const currencyData = query.Currency || query.currency;
        if (defaultStore.currencyList?.Items) {
          for (let i = 0; i < defaultStore.currencyList.Items.length; i++) {
            if (currencyData == defaultStore.currencyList.Items[i].Code) {
              defaultStore.currency = defaultStore.currencyList.Items[i];
              defaultStore.FirstEntry = false;
              if (defaultStore.isLocalStorage == true) {
                localStorage.setItem(
                  'currency',
                  JSON.stringify(defaultStore.currencyList.Items[i]),
                );
              }
            }
          }
        }
      }
    }
  },
  { immediate: true, deep: true },
);
</script>
