import { $get, $post } from '@/api/request';
import { AxiosRequestConfig } from 'axios';
import {
  searchDestinationType,
  searchVehiclesType,
  chooseVehicleType,
  otherChooseVehicleType,
  getOrderDetailType,
  createOrderType,
  getTermsAndConditionsType,
  modifyDriverType,
  modifyFlightNumberType,
  modifyExtrasType,
  getCancelInfoType,
  cancelOrderType,
  queryByOrderNoType,
  queryOrderType,
  emailType,
  getContentsByTypeType,
  contactUsByEmailType,
  getOrderVoucherType,
  addSiteLogType,
  MarketingTagItem,
  DriverInfoType,
  CreateCustomerResponse,
  CreateOrderConfirmInputRepuest,
} from '@/api/params';
// 获取首页展示信息。包含亮点、问题解答、附近信息、供应商、司机居住地
export const getHomeInfo = () => {
  return $get('/api/XiWanCar/carCommon/getHomeInfo');
};
// 获取币种列表
export const getCurrencies = () => {
  return $get('api/XiWanCar/carCommon/getCurrencies', {}, false);
};
// 获取国家
export const getCountries = () => {
  return $get('/api/XiWanCar/carCommon/getCountries', {}, false);
};
// 搜索目的地
export const searchDestination = (
  data: searchDestinationType,
  loading = true,
  cancel = false,
) => {
  return $get(
    '/api/XiWanCar/carLocation/searchDestination',
    data,
    loading,
    cancel,
  );
};
// PV统计
export const pvLogSaveLog = (data: any, loading = true) => {
  return $post('/api/XiWanCar/pvLog/saveLog', data, loading);
};
// 内部统计接口
export const Tracker = (data: any) => {
  return $get('/Tracker', data, false);
};
// 搜索可用车辆
export const searchVehicles = (data: searchVehiclesType) => {
  return $post('/api/XiWanCar/carVehicle/searchVehicles', data, false, {
    retry: 3,
  });
};
// 获取车辆过滤条件
export const getVehicleFilterCriteria = () => {
  return $get('/api/XiWanCar/carVehicle/getVehicleFilterCriteria');
};
// 选择车辆
export const chooseVehicle = (
  data: chooseVehicleType | any,
  loading: boolean = true,
) => {
  return $get(
    '/api/XiWanCar/carVehicle/chooseVehicle',
    data,
    loading,
    false,
    3,
  );
};
// 选择车辆（其他平台跳转）
export const otherChooseVehicle = (
  data: otherChooseVehicleType,
  loading: boolean = true,
  config: AxiosRequestConfig,
) => {
  return $post(
    '/api/XiWanCar/externalLink/chooseVehicle',
    data,
    loading,
    config,
  );
};
// 获取政策
export const getTermsAndConditions = (data: getTermsAndConditionsType) => {
  return $post('/api/XiWanCar/carVehicle/getTermsAndConditions', data);
};
// 获取订单详情
export const getOrderDetail = (
  data: getOrderDetailType,
  loading: boolean = true,
) => {
  return $get('/api/XiWanCar/carOrder/getOrderDetail', data, loading);
};
// 创建订单
export const createOrder = (data: createOrderType) => {
  return $post('/api/XiWanCar/carOrder/createOrder', data);
};
// 修改订单驾驶员信息（看供应商是否支持）
export const modifyDriver = (data: modifyDriverType) => {
  return $post('/api/XiWanCar/carOrder/modifyDriver', data);
};
// 修改订单航班号（看供应商是否支持）
export const modifyFlightNumber = (data: modifyFlightNumberType) => {
  return $post('/api/XiWanCar/carOrder/modifyFlightNumber', data);
};
// 修改附加设备
export const modifyExtras = (data: modifyExtrasType) => {
  return $post('/api/XiWanCar/carOrder/modifyExtras', data);
};
// 获取订单取消页信息
export const getCancelInfo = (data: getCancelInfoType) => {
  return $get('/api/XiWanCar/carOrder/getCancelInfo', data);
};
// 取消订单
export const cancelOrder = (data: cancelOrderType) => {
  return $post('/api/XiWanCar/carOrder/cancelOrder', data);
};
// 查询订单（订单号）
export const queryByOrderNo = (data: queryByOrderNoType) => {
  return $get('/api/XiWanCar/carOrder/queryByOrderNo', data);
};
// 查询订单（订单号+邮箱）
export const queryOrder = (data: queryOrderType) => {
  return $get('/api/XiWanCar/carOrder/queryOrder', data);
};
// 发送确认邮件
export const resendConfirmEmail = (data: emailType) => {
  return $get('/api/XiWanCar/carOrder/resendConfirmEmail', data);
};
// 获取访问者iP详情，包含默认国家币种语言
export const getIPDetail = () => {
  return $get('/api/XiWanCar/carCommon/getIPDetail');
};
// 获取文案内容
export const getContentsByType = (
  data: getContentsByTypeType,
  loading: boolean = true,
) => {
  return $get('/api/XiWanCar/carCommon/getContentsByType', data, loading);
};
// 联系我们-邮箱
export const contactUsByEmail = (data: contactUsByEmailType) => {
  return $post('api/XiWanCar/carCommon/contactUsByEmail', data);
};
// 获取订单凭证
export const getOrderVoucher = (data: getOrderVoucherType) => {
  return $get('/api/XiWanCar/carOrder/getOrderVoucher', data);
};
// 页面统计
export const addSiteLog = (data: addSiteLogType) => {
  return $post('/api/XiWanCar/carVehicle/addSiteLog', data, false);
};
// 营销文案
export const getMarketingTags = (
  data: any,
): Promise<Array<MarketingTagItem>> => {
  return $get('api/XiWanCar/carVehicle/GetMarketingTags', data, false);
};
// 提交收单信息前创建客户信息
export const createCustomer = (
  data: DriverInfoType,
): Promise<CreateCustomerResponse> => {
  return $post('/api/XiWanCar/carOrder/createCustomer?payChannel=5', data);
};
// 订单创建后的付款结果
export const createOrderResult = (data: CreateOrderConfirmInputRepuest) => {
  return $post('/api/XiWanCar/carOrder/createOrderResult', data);
};
